$(function(){
    $(document).ready(function() {
    	// Flash messages
    	$(".flash-message").each(function(index, el) {
    		var type = $(this).data('type');
    		var message = $(this).data('message');

    		console.log(type + " " + message);

    		if (type == 'warning') {
    			new PNotify({
					title: 'Upozornění',
					text: message,
					styling: 'bootstrap3'
				}); 	
    		} else if (type == 'info') {
    			new PNotify({
					title: 'Info',
					text: message,
					type: 'info',
					styling: 'bootstrap3'
				});
    		} else if (type == 'success') {
    			new PNotify({
					title: 'Povedlo se',
					text: message,
					type: 'success',
					styling: 'bootstrap3'
				});
    		} else if (type == 'error') {
    			new PNotify({
					title: 'Chyba',
					text: message,
					type: 'error',
					styling: 'bootstrap3'
				});
    		} else {
    			new PNotify({
					title: 'Info',
					text: message,
					type: 'info',
					styling: 'bootstrap3'
				});
    		}
    	});

    	// User Form
    	if ($("#create_laborer_form").length > 0 || $("#edit_laborer_form").length > 0) {
    		console.log("form is present");

    		function disableInputs($inputs) {
    			$inputs.each(function(index, el) {
    				$(this).prop('disabled', true);
    				console.log("disabling input: " + $(this));
    			});
    		}

    		function enableInputs($inputs) {
    			$inputs.each(function(index, el) {
    				$(this).prop('disabled', false);
    				console.log("enabling input: " + $(this));
    			});
    		}

    		if ($("#create_laborer_form").length > 0) {
    			console.log("form is present #create_laborer_form");
		    	function handleDisabled() {
		    		console.log("handling disabled function");
		    		if ($("#billing_address_is_same").is(':checked')) {
		    			disableInputs($(".input_billing_address"));
		    		} else {
		    			enableInputs($(".input_billing_address"));
		    		}

		    		if ($("#bank_is_czech").is(':checked')) {
		    			disableInputs($(".input_bank_is_czech"));
		    		} else {
		    			enableInputs($(".input_bank_is_czech"));
		    		}

		    		if ($("#correspondent_address_is_same").is(':checked')) {
		    			disableInputs($(".input_correspondent_address"));
		    		} else {
		    			enableInputs($(".input_correspondent_address"));
		    		}
		    	}
    		}

    		if ($("#frm-userEditForm-form").length > 0) {
    			console.log("form is present #frm-userEditForm-form");
    			function handleDisabled() {
		    		if ($("#billing_address_is_same").is(':checked')) {
		    			disableInputs($(".input_billing_address"));
		    		} else {
		    			enableInputs($(".input_billing_address"));
		    		}

		    		if ($("#bank_is_czech").is(':checked')) {
		    			disableInputs($(".input_bank_is_czech"));
		    		} else {
		    			enableInputs($(".input_bank_is_czech"));
		    		}

		    		if ($("#correspondent_address_is_same").is(':checked')) {
		    			disableInputs($(".input_correspondent_address"));
		    		} else {
		    			enableInputs($(".input_correspondent_address"));
		    		}
		    	}	
    		}


	    	$("#billing_address_is_same").change(function(event) {
	    		handleDisabled();
	    		console.log("handle disabled billing adress");
	    	});

	    	$("#bank_is_czech").change(function(event) {
	    		handleDisabled();
	    		console.log("handle disabled bankisczech adress");
	    	});

	    	$("#correspondent_address_is_same").change(function(event) {
	    		handleDisabled();
	    		console.log("handle disabled correspondent adress");
	    	});

	    	handleDisabled();
    	}

    	// Contract date range
		var daterangeLocale = {
            "format": "D.M.YYYY",
            "separator": " - ",
            "applyLabel": "Použít",
            "cancelLabel": "Zrušit",
            "fromLabel": "Od",
            "toLabel": "Do",
            "customRangeLabel": "Vlastní",
            "weekLabel": "T",
            "daysOfWeek": [
                "Ne",
                "Po",
                "Út",
                "St",
                "Čt",
                "Pá",
                "So"
            ],
            "monthNames": [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec"
            ],
            "firstDay": 1
		};

    	var contractDates = [
			{
				hiddenInputs: {
					start: $("input[name='date_from']"),
					end: $("input[name='date_until']")
				},
				daterangeEl: $('#contract-date')
			},
            {
                hiddenInputs: {
                    start: $("input[name='assembly_date_from']"),
                    end: $("input[name='assembly_date_until']")
                },
                daterangeEl: $('#assembly-date')
            },
            {
                hiddenInputs: {
                    start: $("input[name='dissassembly_date_from']"),
                    end: $("input[name='dissassembly_date_until']")
                },
                daterangeEl: $('#dissassembly-date')
            },
            {
                hiddenInputs: {
                    start: $("input[name='loading_date']"),
                    end: $("input[name='unloading_date']")
                },
                daterangeEl: $('#loading-date')
            }
		];

    	if($('input[name="reservation"]').length) {
            contractDates.forEach(function (contractDate) {
                contractDate.daterangeEl.daterangepicker({
                    "locale": daterangeLocale,
                    autoApply: true,
                    autoUpdateInput: false
                }, function(start, end, label) {
                    console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
                });

                if (contractDate.hiddenInputs.start.val() !== '' && contractDate.hiddenInputs.end.val() !== '') {
                    contractDate.daterangeEl.data('daterangepicker').setStartDate(contractDate.hiddenInputs.start.val());
                    contractDate.daterangeEl.data('daterangepicker').setEndDate(contractDate.hiddenInputs.end.val());
                    // contractDate.daterangeEl.data('daterangepicker').setStartDate(moment(contractDate.hiddenInputs.start.val(), "YYYY-MM-DD HH:mm:ss").format("D.M.YYYY"));
                    // contractDate.daterangeEl.data('daterangepicker').setEndDate(moment(contractDate.hiddenInputs.end.val(), "YYYY-MM-DD HH:mm:ss").format("D.M.YYYY"));
                }

                contractDate.daterangeEl.on('apply.daterangepicker', function(ev, picker) {
                    console.log('applying date_')
                    $(this).val(picker.startDate.format("D.M.YYYY") + ' - ' + picker.endDate.format("D.M.YYYY"));
                    contractDate.hiddenInputs.start.val(picker.startDate.format("D.M.YYYY"));
                    contractDate.hiddenInputs.end.val(picker.endDate.format("D.M.YYYY"));
                });

                contractDate.daterangeEl.on('cancel.daterangepicker', function(ev, picker) {
                    $(this).val('');
                    contractDate.hiddenInputs.start.val('');
                    contractDate.hiddenInputs.end.val('');
                });
            });
		}

        // if ($("input[name='date_from']").val() && $("input[name='date_until']").val()) {
    		// $('#contract-date').daterangepicker({
			//     "locale": daterangeLocale,
			// }, function(start, end, label) {
			//   console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
			// });
        //
        //     test = moment("12-25-1995", "MM-DD-YYYY");
			//
			// $('#contract-date').data('daterangepicker').setStartDate($("input[name='date_from']").val());
			// $('#contract-date').data('daterangepicker').setEndDate($("input[name='date_until']").val());
        // } else {
			// $('#contract-date').daterangepicker({
			// 	autoUpdateInput: false,
        //         "locale": daterangeLocale,
			// }, function(start, end, label) {
			//   console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
			// });
        // }
        //
        // $('#contract-date').on('apply.daterangepicker', function(ev, picker) {
        // 	console.log('applying date_')
        //     $(this).val(picker.startDate.format("D.M.YYYY") + ' - ' + picker.endDate.format("D.M.YYYY"));
        //     $("input[name='date_from']").val(picker.startDate.format("D.M.YYYY"));
        //     $("input[name='date_until']").val(picker.endDate.format("D.M.YYYY"));
        // });
        //
        // $('#contract-date').on('cancel.daterangepicker', function(ev, picker) {
        //     $(this).val('');
        //     $("input[name='date_from']").val('');
        //     $("input[name='date_until']").val('');
        // });
        //
        // if ($("input[name='assembly_date_from']").val() && $("input[name='assembly_date_until']").val()) {
        //     $('#assembly-date').daterangepicker({
        //         "locale": daterangeLocale,
        //     }, function(start, end, label) {
        //         console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
        //     });
        //
        //     $('#assembly-date').data('daterangepicker').setStartDate($("input[name='assembly_date_from']").val());
        //     $('#assembly-date').data('daterangepicker').setEndDate($("input[name='assembly_date_until']").val());
        // } else {
        //     $('#assembly-date').daterangepicker({
        //         autoUpdateInput: false,
        //         "locale": daterangeLocale,
        //     }, function(start, end, label) {
        //         console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
        //     });
        // }
        //
        // $('#assembly-date').on('apply.daterangepicker', function(ev, picker) {
        //     $(this).val(picker.startDate.format("D.M.YYYY") + ' - ' + picker.endDate.format("D.M.YYYY"));
        //     $("input[name='assembly_date_from']").val(picker.startDate.format("D.M.YYYY"));
        //     $("input[name='assembly_date_until']").val(picker.endDate.format("D.M.YYYY"));
        // });
        //
        // $('#assembly-date').on('cancel.daterangepicker', function(ev, picker) {
        //     $(this).val('');
        //     $("input[name='assembly_date_from']").val('');
        //     $("input[name='assembly_date_until']").val('');
        // });
        //
        // if ($("input[name='loading_date']").val() && $("input[name='unloading_date']").val()) {
        //     $('#loading-date').daterangepicker({
        //         "locale": daterangeLocale,
        //     }, function(start, end, label) {
        //         console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
        //     });
        //
        //     $('#loading-date').data('daterangepicker').setStartDate($("input[name='loading_date']").val());
        //     $('#loading-date').data('daterangepicker').setEndDate($("input[name='unloading_date']").val());
        // } else {
        //     $('#loading-date').daterangepicker({
        //         autoUpdateInput: false,
        //         "locale": daterangeLocale,
        //     }, function(start, end, label) {
        //         console.log("New date range selected: " + start.format("D.M.YYYY") + " to " + end.format("D.M.YYYY") + " (predefined range: ' + label + ')");
        //     });
        // }
        //
        // $('#loading-date').on('apply.daterangepicker', function(ev, picker) {
        //     $(this).val(picker.startDate.format("D.M.YYYY") + ' - ' + picker.endDate.format("D.M.YYYY"));
        //     $("input[name='loading_date']").val(picker.startDate.format("D.M.YYYY"));
        //     $("input[name='unloading_date']").val(picker.endDate.format("D.M.YYYY"));
        // });
        //
        // $('#loading-date').on('cancel.daterangepicker', function(ev, picker) {
        //     $(this).val('');
        //     $("input[name='loading_date']").val('');
        //     $("input[name='unloading_date']").val('');
        // });

		// Datatable
		var datatableLang = {
            "sEmptyTable":     "Tabulka neobsahuje žádná data",
            "sInfo":           "Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů",
            "sInfoEmpty":      "Zobrazuji 0 až 0 z 0 záznamů",
            "sInfoFiltered":   "(filtrováno z celkem _MAX_ záznamů)",
            "sInfoPostFix":    "",
            "sInfoThousands":  " ",
            "sLengthMenu":     "Zobraz záznamů _MENU_",
            "sLoadingRecords": "Načítám...",
            "sProcessing":     "Provádím...",
            "sSearch":         "Hledat:",
            "sZeroRecords":    "Žádné záznamy nebyly nalezeny",
            "oPaginate": {
                "sFirst":    "První",
                "sLast":     "Poslední",
                "sNext":     "Další",
                "sPrevious": "Předchozí"
            },
            "oAria": {
                "sSortAscending":  ": aktivujte pro řazení sloupce vzestupně",
                "sSortDescending": ": aktivujte pro řazení sloupce sestupně"
            }
		};

		$('.datatable').dataTable({
			language: datatableLang
		});

		$('.datatableContract').dataTable({
			language: datatableLang,
            order: [[ 4, "desc" ]]
		});

        $('.datatableItemOperation').dataTable({
            language: datatableLang,
            order: [[ 0, "desc" ]]
        });

		$('[data-toggle="tooltip"]').tooltip(); 

		$('.datepicker').datetimepicker({
			"format": "D.M.YYYY",
			allowInputToggle: true,
			locale: 'cs'  //.en
			// format: 'dd.mm.yyyy',  // mm/dd/yyyy
			// minView: 'month',
			//startDate: '2016-09-01',
			//endDate: '2016-09-15',
			// autoclose: true,
			// todayBtn: true
		});

		// Activity
		$('tr[data-activity-type=data]').each(function(index, el) {
			var id = $(this).attr('data-activity-id');
			var $showFormButton = $(this).find('.btn-primary');
			var $trForm = $('tr[data-activity-type=form][data-activity-id='+ id +']');
			var $trData = $(this);
			var $hideFormButton = $trForm.find('.btn-danger');
			
			$showFormButton.click(function(event) {
				event.preventDefault();
				$trData.css('display', 'none');
				$trForm.css('display', 'table-row');
			});

			$hideFormButton.click(function(event) {
				event.preventDefault();
				$trData.css('display', 'table-row');
				$trForm.css('display', 'none');
			});
		});

		$('.work-record-toggler').click(function(event) {
			event.preventDefault();

			recordId = $(this).attr('data-work-record-target');

			console.log(recordId);

			$(".work-record__row[data-work-record-id="+ recordId +"]").toggleClass('work-record__row--hidden');
            $(".work-record__row[data-work-record-id="+ recordId +"]").next().toggleClass('work-record__row--hidden');
		});
    });        
});